@import url('./vendor/fonts.css');

body, html {
  max-width: 100vw;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

input, textarea {
  font-family: 'Roboto', 'Lucida Sans', Verdana, sans-serif;
}

button {
  font-family: 'Roboto', 'Lucida Sans', Verdana, sans-serif;
  cursor: pointer;
}