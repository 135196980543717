.header {
  width: 100%;
  background-color: #FFF;
  position: fixed;
  z-index: 1;
  border-bottom: solid 1px #c28ea7;
  z-index: 2;
}

.header__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 70px 20px;
  border-bottom: solid 1px #c28ea7;
  width: 100%;
  box-sizing: border-box;
}

.header__logo {
  font-family: 'Comfortaa', 'Segoe UI', Verdana, sans-serif;
  text-transform: lowercase;
  font-weight: 400;
  font-size: 36px;
}

.header__menu {
  display: flex;
  margin-left: auto;
  gap: 28px;
}

.header__links {
  display: flex;
  gap: 24px;
}

.header__user {
  display: flex;
  align-items: center;
  gap: 10px;
  max-height: 40px;
  background: none;
  border: none;
  width: fit-content;
  margin: 0 10px;
  font-weight: 300;
}

.header__user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  max-width: 100px;
}

.header__link {
  font-weight: 300;
  font-size: 18px;
  text-decoration: none;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header__link-title {
  font-size: 14px;
}

.header__subtitle {
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
}

.header__button {
  background: none;
  border: none;
  font-weight: 00;
  font-size: 18px;
  position: relative;
}

.header__icon {
  width: 24px;
  height: 24px;
}

.header__cart-num {
  background-color: #ff91c8;
  color: #2c1822;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  position: absolute;
  top: -6px;
  right: -14px;
}


.header__categories {
  display: flex;
  padding: 24px 0;
  gap: 32px;
  justify-content: center;
}

.header__category {
  font-weight: 300;
  font-size: 20px;
  text-decoration: none;
  color: #000;
}

.header__menu-button {
  background: none;
  border: none;
  height: 24px;
  width: 24px;
  background-image: url('../../images/menu\ black.svg');
}


@media screen and (min-width: 600px) and (max-width: 1300px) {
  .header__top {
    flex-flow: row wrap;
  }

  .header__menu {
    gap: 20px;
  }

  .header__links {
    gap: 14px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    padding: 30px 30px 0;
  }

  .header__top {
    flex-flow: row wrap;
  }
}