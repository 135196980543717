.page {
  min-height: 100vh;
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', 'Lucida Sans', Verdana, sans-serif;
  overflow-x: hidden;
}

.page__main {
  padding-top: 134px;
}

@media screen and (max-width: 600px) {
  .page__main {
    padding-top: 28px;
  }
}